import * as React from 'react';
import styled from '@emotion/styled';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface FormProps {
  className?: string;
}

const Form: React.FC<FormProps> = ({ children, className }) => (
  <StyledForm className={className}>{children}</StyledForm>
);

export default Form;
