import * as React from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase';
import IndexLayout from '../layouts';
import Page from '../components/Page';
import Form from '../components/Form';
import Container from '../components/Container';
import { Button, TextField, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../store';
import { checkIsClient } from '../utils';

const useStyles = makeStyles({
  root: {
    width: '400px',
    color: 'white',
    marginBottom: '12px',
  },
  input: {
    fontSize: '16px',
  },
  label: {
    fontSize: '18px',
  },
  alert: {
    fontSize: '14px',
  },
});

const IndexPage: React.FC = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');

  // If already authenticated, redirect user to the dashboard
  let auth;
  if (checkIsClient()) auth = useSelector((state: RootState) => state.firebase.auth);
  if (isLoaded(auth) && !isEmpty(auth)) {
    const currentUser = firebase?.auth().currentUser;
    if (currentUser)
      currentUser.getIdTokenResult().then(idTokenResult => {
        if (idTokenResult?.claims.admin) navigate('/dashboard');
      });
  }

  const handleSubmit = async () => {
    try {
      const credentials = await firebase?.auth().signInWithEmailAndPassword(email, password);
      const idTokenResult = await firebase?.auth().currentUser?.getIdTokenResult();
      if (credentials && credentials.user) {
        if (idTokenResult?.claims.admin) navigate('/dashboard');
        // Redirect to dashboard after authentication
        else {
          await firebase?.auth().signOut();
          localStorage.removeItem('currentUser');
        }
      }

      setOpen(true);
      setError('Error: Invalid Admin User Account');
    } catch (error) {
      console.error(error);
      setOpen(true);
      setError(`${error}`);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <IndexLayout>
      <Page>
        <Container>
          <Form>
            <TextField
              className={classes.root}
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              required
              value={email}
              autoComplete="email"
              autoFocus
              onChange={e => setEmail(e.target.value)}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
            />

            <TextField
              className={classes.root}
              label="Password"
              variant="outlined"
              margin="normal"
              type="password"
              required
              value={password}
              autoComplete="email"
              autoFocus
              onChange={e => setPassword(e.target.value)}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
            />

            <Button
              className={classes.root}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: '8px' }}>
              Signin
            </Button>
          </Form>

          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" variant="filled" className={classes.alert}>
              {error}
            </Alert>
          </Snackbar>
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default IndexPage;
